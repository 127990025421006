html,
body {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translate3d(0px, 0px, 1px);
  overflow: hidden;
  min-height: 100dvh;
}
@media only screen and (max-width: 600px) {
  body {
    -webkit-user-select: none;
  }
}
.MuiDrawer-root.MuiDrawer-modal .MuiBackdrop-root {
  -webkit-transform: translate3d(0px, 0px, 0px);
}
a {
  cursor: pointer;
  color: #2196f3;
  text-decoration: none;
}
.ql-container .ql-editor {
  min-height: 120px;
  font-size: 1rem;
}
.katex-display {
  display: inline-block;
  margin: 4px;
  margin-top: 0;
}
.dropzone {
  min-height: 110px !important;
}
.ql-video {
  width: 400px;
  height: 225px;
}
.success .MuiInput-underline:after {
  border-bottom: 2px solid #4caf50;
  transform: scaleX(1);
}
.success .MuiFormLabel-root {
  color: #4caf50 !important;
}
